import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import { ThemeProvider, createTheme } from "@aws-amplify/ui-react";
import { studioTheme } from './ui-components';
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports';
import './index.css';


Amplify.configure(awsmobile);

const updatedTheme = createTheme({
    // Extend the theme to update the button color
    name: "aurinia-lnws-form-theme-updates", 
    tokens: {
        components: {
            button: {
                primary: {
                    backgroundColor: {
                        value: "#b71c1c"
                    },
                },
            },
        },
    },
}, studioTheme)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={updatedTheme}>
        <App />
    </ThemeProvider>
);











