/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getAuriniaLNWarningSignsSignUp } from "../graphql/queries";
import { updateAuriniaLNWarningSignsSignUp } from "../graphql/mutations";
const client = generateClient();
export default function AuriniaLNWarningSignsSignUpUpdateForm(props) {
  const {
    id: idProp,
    auriniaLNWarningSignsSignUp: auriniaLNWarningSignsSignUpModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    zipcode: "",
    title: "",
    specialty: "",
    repcontact: false,
    mslcontact: false,
  };
  const [firstname, setFirstname] = React.useState(initialValues.firstname);
  const [lastname, setLastname] = React.useState(initialValues.lastname);
  const [email, setEmail] = React.useState(initialValues.email);
  const [zipcode, setZipcode] = React.useState(initialValues.zipcode);
  const [title, setTitle] = React.useState(initialValues.title);
  const [specialty, setSpecialty] = React.useState(initialValues.specialty);
  const [repcontact, setRepcontact] = React.useState(initialValues.repcontact);
  const [mslcontact, setMslcontact] = React.useState(initialValues.mslcontact);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = auriniaLNWarningSignsSignUpRecord
      ? { ...initialValues, ...auriniaLNWarningSignsSignUpRecord }
      : initialValues;
    setFirstname(cleanValues.firstname);
    setLastname(cleanValues.lastname);
    setEmail(cleanValues.email);
    setZipcode(cleanValues.zipcode);
    setTitle(cleanValues.title);
    setSpecialty(cleanValues.specialty);
    setRepcontact(cleanValues.repcontact);
    setMslcontact(cleanValues.mslcontact);
    setErrors({});
  };
  const [
    auriniaLNWarningSignsSignUpRecord,
    setAuriniaLNWarningSignsSignUpRecord,
  ] = React.useState(auriniaLNWarningSignsSignUpModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getAuriniaLNWarningSignsSignUp.replaceAll(
                "__typename",
                ""
              ),
              variables: { id: idProp },
            })
          )?.data?.getAuriniaLNWarningSignsSignUp
        : auriniaLNWarningSignsSignUpModelProp;
      setAuriniaLNWarningSignsSignUpRecord(record);
    };
    queryData();
  }, [idProp, auriniaLNWarningSignsSignUpModelProp]);
  React.useEffect(resetStateValues, [auriniaLNWarningSignsSignUpRecord]);
  const validations = {
    firstname: [{ type: "Required" }],
    lastname: [{ type: "Required" }],
    email: [{ type: "Required" }, { type: "Email" }],
    zipcode: [{ type: "Required" }],
    title: [],
    specialty: [],
    repcontact: [],
    mslcontact: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          firstname,
          lastname,
          email,
          zipcode,
          title: title ?? null,
          specialty: specialty ?? null,
          repcontact: repcontact ?? null,
          mslcontact: mslcontact ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateAuriniaLNWarningSignsSignUp.replaceAll(
              "__typename",
              ""
            ),
            variables: {
              input: {
                id: auriniaLNWarningSignsSignUpRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "AuriniaLNWarningSignsSignUpUpdateForm")}
      {...rest}
    >
      <TextField
        label="Firstname"
        isRequired={true}
        isReadOnly={false}
        value={firstname}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname: value,
              lastname,
              email,
              zipcode,
              title,
              specialty,
              repcontact,
              mslcontact,
            };
            const result = onChange(modelFields);
            value = result?.firstname ?? value;
          }
          if (errors.firstname?.hasError) {
            runValidationTasks("firstname", value);
          }
          setFirstname(value);
        }}
        onBlur={() => runValidationTasks("firstname", firstname)}
        errorMessage={errors.firstname?.errorMessage}
        hasError={errors.firstname?.hasError}
        {...getOverrideProps(overrides, "firstname")}
      ></TextField>
      <TextField
        label="Lastname"
        isRequired={true}
        isReadOnly={false}
        value={lastname}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname: value,
              email,
              zipcode,
              title,
              specialty,
              repcontact,
              mslcontact,
            };
            const result = onChange(modelFields);
            value = result?.lastname ?? value;
          }
          if (errors.lastname?.hasError) {
            runValidationTasks("lastname", value);
          }
          setLastname(value);
        }}
        onBlur={() => runValidationTasks("lastname", lastname)}
        errorMessage={errors.lastname?.errorMessage}
        hasError={errors.lastname?.hasError}
        {...getOverrideProps(overrides, "lastname")}
      ></TextField>
      <TextField
        label="Email"
        isRequired={true}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email: value,
              zipcode,
              title,
              specialty,
              repcontact,
              mslcontact,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label="Zipcode"
        isRequired={true}
        isReadOnly={false}
        value={zipcode}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              zipcode: value,
              title,
              specialty,
              repcontact,
              mslcontact,
            };
            const result = onChange(modelFields);
            value = result?.zipcode ?? value;
          }
          if (errors.zipcode?.hasError) {
            runValidationTasks("zipcode", value);
          }
          setZipcode(value);
        }}
        onBlur={() => runValidationTasks("zipcode", zipcode)}
        errorMessage={errors.zipcode?.errorMessage}
        hasError={errors.zipcode?.hasError}
        {...getOverrideProps(overrides, "zipcode")}
      ></TextField>
      <TextField
        label="Title"
        isRequired={false}
        isReadOnly={false}
        value={title}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              zipcode,
              title: value,
              specialty,
              repcontact,
              mslcontact,
            };
            const result = onChange(modelFields);
            value = result?.title ?? value;
          }
          if (errors.title?.hasError) {
            runValidationTasks("title", value);
          }
          setTitle(value);
        }}
        onBlur={() => runValidationTasks("title", title)}
        errorMessage={errors.title?.errorMessage}
        hasError={errors.title?.hasError}
        {...getOverrideProps(overrides, "title")}
      ></TextField>
      <TextField
        label="Specialty"
        isRequired={false}
        isReadOnly={false}
        value={specialty}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              zipcode,
              title,
              specialty: value,
              repcontact,
              mslcontact,
            };
            const result = onChange(modelFields);
            value = result?.specialty ?? value;
          }
          if (errors.specialty?.hasError) {
            runValidationTasks("specialty", value);
          }
          setSpecialty(value);
        }}
        onBlur={() => runValidationTasks("specialty", specialty)}
        errorMessage={errors.specialty?.errorMessage}
        hasError={errors.specialty?.hasError}
        {...getOverrideProps(overrides, "specialty")}
      ></TextField>
      <SwitchField
        label="Repcontact"
        defaultChecked={false}
        isDisabled={false}
        isChecked={repcontact}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              zipcode,
              title,
              specialty,
              repcontact: value,
              mslcontact,
            };
            const result = onChange(modelFields);
            value = result?.repcontact ?? value;
          }
          if (errors.repcontact?.hasError) {
            runValidationTasks("repcontact", value);
          }
          setRepcontact(value);
        }}
        onBlur={() => runValidationTasks("repcontact", repcontact)}
        errorMessage={errors.repcontact?.errorMessage}
        hasError={errors.repcontact?.hasError}
        {...getOverrideProps(overrides, "repcontact")}
      ></SwitchField>
      <SwitchField
        label="Mslcontact"
        defaultChecked={false}
        isDisabled={false}
        isChecked={mslcontact}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              zipcode,
              title,
              specialty,
              repcontact,
              mslcontact: value,
            };
            const result = onChange(modelFields);
            value = result?.mslcontact ?? value;
          }
          if (errors.mslcontact?.hasError) {
            runValidationTasks("mslcontact", value);
          }
          setMslcontact(value);
        }}
        onBlur={() => runValidationTasks("mslcontact", mslcontact)}
        errorMessage={errors.mslcontact?.errorMessage}
        hasError={errors.mslcontact?.hasError}
        {...getOverrideProps(overrides, "mslcontact")}
      ></SwitchField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || auriniaLNWarningSignsSignUpModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || auriniaLNWarningSignsSignUpModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
