
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import SignUpArrow from './sign-up-arrow.png'
import AuriniaLogo from './Aurinia-Logo-RGB-033020-v01.svg'
import gtag from 'ga-gtag'
import {
  AuriniaLNWarningSignsSignUpCreateForm
} from './ui-components';


function App() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="app">
      <div className="headercontainer">
        <div className="headerbg">
          <div className="container" >
            <div className="row pt-3 pt-lg-5 justify-content-center">
              <div className="col-11 col-lg-10">
                
                <h1 className="yellowheader">Uncover the Warning Signs of Lupus Nephritis <span className="parensStyle">(</span>LN<span className="parensStyle">)</span>.<br /> New Website Coming Soon!</h1>
                <h2 className="whitesub pt-3 pt-lg-2">A significant number of patients with SLE have or will develop LN in the first few years of diagnosis<sup className="supText">1-3</sup></h2>
              </div>
            </div>
            <div className="row pt-3 justify-content-center">
              <div className="col-11 col-lg-10 d-flex flex-column flex-lg-row align-items-center">
                <h2 className="signUptext">Come back to stay up to date and keep learning more.</h2>
                <a href="#sign-up-for-updates" className="CTA-Button">Sign Up for Updates <img src={SignUpArrow} alt="Sign Up for Updates" className='icons'></img>
                </a>
              </div>
            </div>
          </div>
          <div className="referenceContainer">
          <div className="container">
            <div className="row pt-3 pt-lg-5 justify-content-center">
              <div className="col-11 col-lg-10">
                <div className="reference">SLE=systemic lupus erythematosus.<br />
                  <strong>1</strong>. Mahajan A, Amelio J, Gairy K, et al. Systemic lupus erythematosus, lupus nephritis and end-stage renal disease: a pragmatic review mapping disease severity and progression. <i>Lupus.</i> 2020;29(9):1011-1020. <strong>2</strong>. Hanly JG, O'Keeffe AG, Su L, et al. The frequency and outcomes of lupus nephritis: results from an international inception cohort study. <i>Rheumatology (Oxford).</i> 2016;55(2):252-262.  <strong>3</strong>. Galindo-Izquierdo M, Rodriguez-Almaraz E, Pego-Reigosa JM, et al. Characterization of patients with lupus nephritis included in a large cohort from the Spanish Society of Rheumatology Registry of Patients with Systemic Lupus Erythematosus (RELESSER). <i>Medicine (Baltimore)</i>. 2016;95(9):e2891.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gradientcontainer">
          <div className="gradient"/>
          </div>
        </div>
      </div>
      <div className="container">
        <a id="sign-up-for-updates"></a>
        <div className="row justify-content-center">
          <div className="col col-lg-10 col-xl-12 mt-3 mt-lg-5">
            <h1 className="whiteheader">Register for important updates on Lupus Nephritis</h1>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10">
            <AuriniaLNWarningSignsSignUpCreateForm
              overrides={{
                SubmitButton: {
                  children: "Submit",
                  className: "submitbutton",
                },
                SectionalElement0: { children: "*Required field" },
                SelectField: {}
              }}
              onSubmit={(fields) => {
                // Example function to trim all string inputs
             
                const updatedFields = {}
                Object.keys(fields).forEach(key => {
                  if (typeof fields[key] === 'string') {
                    updatedFields[key] = fields[key].trim()
                  } else {
                    updatedFields[key] = fields[key]
                  }
                })
                return updatedFields

              }}
              onSuccess={() => {
                gtag('event', 'Sign_Up_Complete', {
                  form_action: 'Opt-In',
                })
                handleShow();
              }}
            />
          </div>
        </div>
      </div>


      <footer className="footer">
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton> </Modal.Header>
          <Modal.Body><h1>Thank you for registering</h1></Modal.Body>

        </Modal>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-11 col-xl-8 text-center mt-3">
              <p className="mb-0">The information on this website is provided by Aurinia for education purposes only and is intended for US health care professionals only.</p> <p className="pt-0">It should not be used for diagnosis or treating a health problem or disease.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-lg-6 text-center my-3">
              <img src={AuriniaLogo} alt="Aurinia Logo" className='footer-logo mb-4 mb-lg-3'></img>
              <nav className="footer-nav d-flex flex-column flex-lg-row align-items-center justify-content-center mb-3">
                <a className="footer-nav-item" rel="noreferrer" href="https://www.auriniapharma.com/contact-us" target="_blank">Contact Us</a>
                <a className="footer-nav-item" rel="noreferrer" href="https://www.auriniapharma.com/privacy-statement" target="_blank">Privacy Policy</a>
                <a className="footer-nav-item" rel="noreferrer" href="https://www.auriniapharma.com/terms-of-use" target="_blank">Terms of Use</a>
                <a className="footer-nav-item cmp-revoke-consent" href="#">Cookie Preferences</a>
              </nav>
              <p className="pb-0 mb-1">Trademarks and logos are the property of Aurinia Pharmaceuticals Inc.</p>
              <p className="pt-0 pb-5">Copyright ©2024 Aurinia Pharma U.S., Inc. All rights reserved.<br />(04/24) US-NA-2400143</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default App;
