/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAuriniaLNWarningSignsSignUp = /* GraphQL */ `
  mutation CreateAuriniaLNWarningSignsSignUp(
    $input: CreateAuriniaLNWarningSignsSignUpInput!
    $condition: ModelAuriniaLNWarningSignsSignUpConditionInput
  ) {
    createAuriniaLNWarningSignsSignUp(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      zipcode
      title
      specialty
      repcontact
      mslcontact
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAuriniaLNWarningSignsSignUp = /* GraphQL */ `
  mutation UpdateAuriniaLNWarningSignsSignUp(
    $input: UpdateAuriniaLNWarningSignsSignUpInput!
    $condition: ModelAuriniaLNWarningSignsSignUpConditionInput
  ) {
    updateAuriniaLNWarningSignsSignUp(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      zipcode
      title
      specialty
      repcontact
      mslcontact
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAuriniaLNWarningSignsSignUp = /* GraphQL */ `
  mutation DeleteAuriniaLNWarningSignsSignUp(
    $input: DeleteAuriniaLNWarningSignsSignUpInput!
    $condition: ModelAuriniaLNWarningSignsSignUpConditionInput
  ) {
    deleteAuriniaLNWarningSignsSignUp(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      zipcode
      title
      specialty
      repcontact
      mslcontact
      createdAt
      updatedAt
      __typename
    }
  }
`;
