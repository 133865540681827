/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  CheckboxField,
  Flex,
  Grid,
  SelectField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createAuriniaLNWarningSignsSignUp } from "../graphql/mutations";
const client = generateClient();
export default function AuriniaLNWarningSignsSignUpCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    zipcode: "",
    title: "",
    specialty: "",
    repcontact: false,
    mslcontact: false,
  };
  const [firstname, setFirstname] = React.useState(initialValues.firstname);
  const [lastname, setLastname] = React.useState(initialValues.lastname);
  const [email, setEmail] = React.useState(initialValues.email);
  const [zipcode, setZipcode] = React.useState(initialValues.zipcode);
  const [title, setTitle] = React.useState(initialValues.title);
  const [specialty, setSpecialty] = React.useState(initialValues.specialty);
  const [repcontact, setRepcontact] = React.useState(initialValues.repcontact);
  const [mslcontact, setMslcontact] = React.useState(initialValues.mslcontact);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setFirstname(initialValues.firstname);
    setLastname(initialValues.lastname);
    setEmail(initialValues.email);
    setZipcode(initialValues.zipcode);
    setTitle(initialValues.title);
    setSpecialty(initialValues.specialty);
    setRepcontact(initialValues.repcontact);
    setMslcontact(initialValues.mslcontact);
    setErrors({});
  };
  const validations = {
    firstname: [{ type: "Required" }],
    lastname: [{ type: "Required" }],
    email: [{ type: "Required" }, { type: "Email" }],
    zipcode: [{ type: "Required" }],
    title: [],
    specialty: [],
    repcontact: [],
    mslcontact: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          firstname,
          lastname,
          email,
          zipcode,
          title,
          specialty,
          repcontact,
          mslcontact,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createAuriniaLNWarningSignsSignUp.replaceAll(
              "__typename",
              ""
            ),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "AuriniaLNWarningSignsSignUpCreateForm")}
      {...rest}
    >
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid0")}
      >
        <TextField
          label={
            <span style={{ display: "inline-flex" }}>
              <span>First name</span>
              <span style={{ color: "red" }}>*</span>
            </span>
          }
          isRequired={true}
          isReadOnly={false}
          value={firstname}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                firstname: value,
                lastname,
                email,
                zipcode,
                title,
                specialty,
                repcontact,
                mslcontact,
              };
              const result = onChange(modelFields);
              value = result?.firstname ?? value;
            }
            if (errors.firstname?.hasError) {
              runValidationTasks("firstname", value);
            }
            setFirstname(value);
          }}
          onBlur={() => runValidationTasks("firstname", firstname)}
          errorMessage={errors.firstname?.errorMessage}
          hasError={errors.firstname?.hasError}
          {...getOverrideProps(overrides, "firstname")}
        ></TextField>
        <TextField
          label={
            <span style={{ display: "inline-flex" }}>
              <span>Last name</span>
              <span style={{ color: "red" }}>*</span>
            </span>
          }
          isRequired={true}
          isReadOnly={false}
          value={lastname}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                firstname,
                lastname: value,
                email,
                zipcode,
                title,
                specialty,
                repcontact,
                mslcontact,
              };
              const result = onChange(modelFields);
              value = result?.lastname ?? value;
            }
            if (errors.lastname?.hasError) {
              runValidationTasks("lastname", value);
            }
            setLastname(value);
          }}
          onBlur={() => runValidationTasks("lastname", lastname)}
          errorMessage={errors.lastname?.errorMessage}
          hasError={errors.lastname?.hasError}
          {...getOverrideProps(overrides, "lastname")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid1")}
      >
        <TextField
          label={
            <span style={{ display: "inline-flex" }}>
              <span>Email address</span>
              <span style={{ color: "red" }}>*</span>
            </span>
          }
          isRequired={true}
          isReadOnly={false}
          value={email}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                firstname,
                lastname,
                email: value,
                zipcode,
                title,
                specialty,
                repcontact,
                mslcontact,
              };
              const result = onChange(modelFields);
              value = result?.email ?? value;
            }
            if (errors.email?.hasError) {
              runValidationTasks("email", value);
            }
            setEmail(value);
          }}
          onBlur={() => runValidationTasks("email", email)}
          errorMessage={errors.email?.errorMessage}
          hasError={errors.email?.hasError}
          {...getOverrideProps(overrides, "email")}
        ></TextField>
        <TextField
          label={
            <span style={{ display: "inline-flex" }}>
              <span>Zip code</span>
              <span style={{ color: "red" }}>*</span>
            </span>
          }
          isRequired={true}
          isReadOnly={false}
          value={zipcode}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                firstname,
                lastname,
                email,
                zipcode: value,
                title,
                specialty,
                repcontact,
                mslcontact,
              };
              const result = onChange(modelFields);
              value = result?.zipcode ?? value;
            }
            if (errors.zipcode?.hasError) {
              runValidationTasks("zipcode", value);
            }
            setZipcode(value);
          }}
          onBlur={() => runValidationTasks("zipcode", zipcode)}
          errorMessage={errors.zipcode?.errorMessage}
          hasError={errors.zipcode?.hasError}
          {...getOverrideProps(overrides, "zipcode")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid2")}
      >
        <SelectField
          label="Title"
          placeholder=" "
          isDisabled={false}
          value={title}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                firstname,
                lastname,
                email,
                zipcode,
                title: value,
                specialty,
                repcontact,
                mslcontact,
              };
              const result = onChange(modelFields);
              value = result?.title ?? value;
            }
            if (errors.title?.hasError) {
              runValidationTasks("title", value);
            }
            setTitle(value);
          }}
          onBlur={() => runValidationTasks("title", title)}
          errorMessage={errors.title?.errorMessage}
          hasError={errors.title?.hasError}
          {...getOverrideProps(overrides, "title")}
        >
          <option
            children="DO"
            value="DO"
            {...getOverrideProps(overrides, "titleoption0")}
          ></option>
          <option
            children="MD"
            value="MD"
            {...getOverrideProps(overrides, "titleoption1")}
          ></option>
          <option
            children="NP"
            value="NP"
            {...getOverrideProps(overrides, "titleoption2")}
          ></option>
          <option
            children="PA"
            value="PA"
            {...getOverrideProps(overrides, "titleoption3")}
          ></option>
          <option
            children="RN"
            value="RN"
            {...getOverrideProps(overrides, "titleoption4")}
          ></option>
          <option
            children="Other"
            value="Other"
            {...getOverrideProps(overrides, "titleoption5")}
          ></option>
        </SelectField>
        <SelectField
          label="My practice specialty"
          placeholder=" "
          isDisabled={false}
          value={specialty}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                firstname,
                lastname,
                email,
                zipcode,
                title,
                specialty: value,
                repcontact,
                mslcontact,
              };
              const result = onChange(modelFields);
              value = result?.specialty ?? value;
            }
            if (errors.specialty?.hasError) {
              runValidationTasks("specialty", value);
            }
            setSpecialty(value);
          }}
          onBlur={() => runValidationTasks("specialty", specialty)}
          errorMessage={errors.specialty?.errorMessage}
          hasError={errors.specialty?.hasError}
          {...getOverrideProps(overrides, "specialty")}
        >
          <option
            children="Primary care/internist"
            value="Primary care/internist"
            {...getOverrideProps(overrides, "specialtyoption0")}
          ></option>
          <option
            children="Rheumatologist"
            value="Rheumatologist"
            {...getOverrideProps(overrides, "specialtyoption1")}
          ></option>
          <option
            children="Nephrologist"
            value="Nephrologist"
            {...getOverrideProps(overrides, "specialtyoption2")}
          ></option>
          <option
            children="Other"
            value="Other"
            {...getOverrideProps(overrides, "specialtyoption3")}
          ></option>
        </SelectField>
      </Grid>
      <CheckboxField
        label="I would like a representative to contact me for more information."
        name="repcontact"
        value="repcontact"
        isDisabled={false}
        checked={repcontact}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              zipcode,
              title,
              specialty,
              repcontact: value,
              mslcontact,
            };
            const result = onChange(modelFields);
            value = result?.repcontact ?? value;
          }
          if (errors.repcontact?.hasError) {
            runValidationTasks("repcontact", value);
          }
          setRepcontact(value);
        }}
        onBlur={() => runValidationTasks("repcontact", repcontact)}
        errorMessage={errors.repcontact?.errorMessage}
        hasError={errors.repcontact?.hasError}
        {...getOverrideProps(overrides, "repcontact")}
      ></CheckboxField>
      <CheckboxField
        label="I would like a medical science liaison to contact me for more information."
        name="mslcontact"
        value="mslcontact"
        isDisabled={false}
        checked={mslcontact}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              zipcode,
              title,
              specialty,
              repcontact,
              mslcontact: value,
            };
            const result = onChange(modelFields);
            value = result?.mslcontact ?? value;
          }
          if (errors.mslcontact?.hasError) {
            runValidationTasks("mslcontact", value);
          }
          setMslcontact(value);
        }}
        onBlur={() => runValidationTasks("mslcontact", mslcontact)}
        errorMessage={errors.mslcontact?.errorMessage}
        hasError={errors.mslcontact?.hasError}
        {...getOverrideProps(overrides, "mslcontact")}
      ></CheckboxField>
      <Text
        children="* Required"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Text>
      <Text
      style={{alignSelf: 'flex-end'}}> By clicking Submit below, you agree that the information you voluntarily provide may be used by Aurinia Pharma U.S., Inc. or other companies acting on its behalf to provide you with educational materials and resources for lupus nephritis. Aurinia will handle your personal information in accordance with Aurinia's <a href="https://www.auriniapharma.com/privacy-statement" className="yellow-link" target="_blank">Privacy Statement</a>. For additional information, please read our Privacy Statement. </Text>
      <Flex
        justifyContent="space-evenly"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
